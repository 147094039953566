import React from 'react';

export const apiAlerts = [
  {
    content: (
      <>
        Version 0 of the Veteran Confirmation API is deprecated and scheduled for deactivation on
        January 30, 2025. Version 1 of the Veteran Confirmation API is now active.
      </>
    ),
    path: '/explore/api/veteran-confirmation',
    slim: false,
    status: 'warning',
  },
  {
    content: (
      <>
        VA is experiencing long delays in approving production access for Veteran benefits APIs.
        Thank you for your patience and support.
      </>
    ),
    path: '/production-access',
    slim: true,
    status: 'default',
  },
  {
    content: (
      <>
        VA is experiencing long delays in approving production access for Veteran benefits APIs.
        Thank you for your patience and support.
      </>
    ),
    path: '/explore/api/benefits-claims/sandbox-access',
    slim: true,
    status: 'default',
  },
  {
    content: (
      <>
        VA is experiencing long delays in approving production access for Veteran benefits APIs.
        Thank you for your patience and support.
      </>
    ),
    path: '/explore/api/benefits-intake/sandbox-access',
    slim: true,
    status: 'default',
  },
  {
    content: (
      <>
        VA is experiencing long delays in approving production access for Veteran benefits APIs.
        Thank you for your patience and support.
      </>
    ),
    path: '/explore/api/benefits-reference-data/sandbox-access',
    slim: true,
    status: 'default',
  },
];
